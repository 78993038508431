@import "_variables.scss";

.overview-header {
  margin-bottom: var(--chakra-space-12-8);
  background-image: url("../../../../public/assets/images/doc-overview-header.png");
  padding: var(--chakra-space-11) var(--chakra-space-12-8)
    var(--chakra-space-11) var(--chakra-space-12-8);
  background-size: 1000px 100%;
  background-repeat: no-repeat;
  border-bottom: solid 1px $color-gradient-light-orange;
  box-shadow: 0px 2px 12px 0px #00000014;
  margin-bottom: 0px;
  align-content: center;

  .text-stack {
    gap: var(--chakra-space-1-6);
    nav > ol {
      margin-left: 0;
    }
  }
  .breadcrumb {
    font-family: $font-family-sfmono;
    color: $color-font-card;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    line-height: 16px;
  }
  .breadcrumb-link {
    &:hover {
      text-decoration: underline;
      color: $color-btn-link-hover;
    }
  }
  .headline {
    font-size: $font-size-xl;
    font-weight: var(--chakra-fontWeights-semibold);
    line-height: 40px;
    color: $color-default-font-color;
  }
  .subheadline {
    font-size: $font-size-med;
    font-weight: var(--chakra-fontWeights-normal);
    line-height: 24px;
    color: $color-font-card;
  }
}
