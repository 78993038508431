@import "_variables.scss";

.tutorials-filter {
  padding-top: var(--chakra-space-12-8);
    .full-group {
        top: 5%;
        margin-left: 20% !important;
        gap: 8px;

        @media screen and (max-width: $breakpoint-mobile-desktop) {
          margin-left: 0% !important;
        }


      }

      .clear {
        cursor: pointer;
        display: inline-block;
        margin-top: 1.6rem;
        font-size: $font-size-small;
        font-weight: 600;
        line-height: 20px;
        color: $color-link-hover-color;
        &:hover {
          color: #1c4e94;
        }
      }
      .divider {
        border: .5px solid $color-description-txt;
      }
      .group-name {
        font-size: 1.4rem;
        font-weight: var(--chakra-fontWeights-bold);
        line-height: var(--chakra-lineHeights-base);
        font-family: $font-family-regular;
        color: rgb(38, 41, 46);
      }
      .chakra-checkbox__label {
        margin-left: $gap-xs;
      }
      .label {
        font-size: 1.6rem;
        max-width: 200px;
        margin-inline-start: var(--chakra-space-3);
        line-height: var(--chakra-lineHeights-base);
        font-family: $font-family-regular;
        color: rgb(38, 41, 46);
        display: inline-flex;
      }
}
